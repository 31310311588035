<template>
    <div class="add-new-travelling-order">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.travellingOrdersCreate')"
            :title="$t('messages.travellingOrdersCreate')"
            @submitPressed="submitPressed"
        />
        <travelling-form :travellingObject="travelOrder" :action="action" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import TravellingForm from './components/TravellingForm.vue'

export default {
    components: {
        TitleBar,
        TravellingForm
    },
    data() {
        return {
            action: null,
            travelOrder: {}
        }
    },
    methods: {
        submitPressed() {
            this.action = 'add'
        }
    }
}
</script>
